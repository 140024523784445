.send-message {
  background-color: #EAF3F7;
  margin-top: 0px !important;
  width: 100%;
  margin: 0 auto;
  .heading-div {
    position: relative;
    width: 80%;
    padding-left: 2.8rem;
    margin-top: 3rem;
    .heading {
      font-family: Inter;
      font-size: 25px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.1em;
      text-align: center;
      text-align: left;
      span {
        font-family: Inter;
        font-size: 25px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.1em;
        text-align: center;
        color: #6280bf;
      }
    }
  }

  .heading-p {
    font-family: 'poppins';
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .form-sec {
    width: 80%;
    margin: auto;
    margin-top: 3rem;
    .message-form {
      width: 407px;
      height: 410px;
      border-radius: 10px 0px 0px 0px;
      background: #D9D9D9;
      box-shadow: 3px 4px 4px 0px #00000040;
      padding: 60px 15px;
      border-radius: 12px;
    }
    .form-image-sec {
      width: 515px;
      height: 453px;

      img {
        position: relative;
        left: -2rem;
        bottom: 3rem;
      }
    }
  }
}

.messageBtn{
  background: #478ba3 !important;
  border: 1px solid #6280BF !important;
  height: fit-content !important;
  width: fit-content !important;
  --bs-btn-padding-y: 0 !important;
  --bs-btn-padding-x: 0 !important;
  border: none !important;
  padding: 1rem !important;
}