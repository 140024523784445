.appointment-heading-text{
    font-size: 1.2rem;
    font-family: 'poppins-bold';
    color: #0B76C4;
}
.sec-text{
    font-size: 1.1rem;
    font-family: 'poppins';
}
.date-text{
    font-size: 1.2rem;
    font-family: 'poppins-bold';
}
.company-text{
    font-size: 1rem;
    font-family: 'poppins-bold';
}
.welcome-text{
    font-size: 0.7;
    font-family: 'poppins';
}
.select{
    display: inline-block;
    font-size: 1rem;
    font-family: 'poppins-bold';
    border: none !important;
    outline: none;
}
.select::after{
    border: none;
    outline: none;
}
.circle{
    display: inline-block;
    position: relative;
    top: 0.45rem;
    left: -1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 25px;
    background-color: #9F303C;
}
.time-heading{
    font-size: 1.5rem;
    font-family: 'poppins-bold';
}
.time-slot{
    padding: 1rem 0;
    border: 2px solid #6280BF;
    color: #4898D3;
    font-size: 1.5rem;
    font-family: 'poppins-bold';
    border-radius: 5px;
}
.time-slot.inactive{
    border: 2px solid gray;
    background-color: gray;
    color: white;
}
.time-slot.active{
    background-color: #7aafd3 ;
    color: white;
}
.time-slot:hover{
    background-color: #7aafd3;
    color: white;
}
.bg-clr{
    background-color: #EAF3F7;
}
.heading-div {
    position: relative;
    width: 80%;
    padding-left: 2.8rem;
    margin-top: 0rem !important;
}
.err-msg{
    color: red;
    text-align: center;
    width: 100%;
    margin: auto;
}
.success-msg{
    color: green;
    text-align: center;
    width: 100%;
    margin: auto;
}